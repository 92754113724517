<template>
  <div class="activity-container">
    <!-- <img src="@/assets/image/activity/1.jpeg" class="activity-img" alt />
    <img src="@/assets/image/activity/2.jpeg" class="activity-img" alt />
    <img src="@/assets/image/activity/3.jpeg" class="activity-img" alt />-->
    <div v-html="info" class="info"></div>
    <!-- 按钮提交 -->
    <van-button
      block
      round
      class="submit"
      :to="{name:'receive-card',query:{agentId:route.query.agentId, goodsId:route.query.goodsId}}"
    >免费领取 全国包邮到家</van-button>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
import areaList from '@/utils/area';
import { cardid } from '@/utils/validate';
import { cloneDeep } from 'lodash';
export default {
  setup() {
    const state = reactive({
      form: {
        walletNumber: '',
        consigneeTelphone: '',
        consigneeRealname: '',
        consigneeAddress: '',
        consigneeAddressDetails: '',
        goodsId: '',
        agentId: '',
      },
      checked: false,
      pattern: /^1\d{10}$/, //手机号码正则匹配
      agreementShow: false,
      agreementShow1: false,
      popupShow: false,
      info: {},
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $http: http, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      /**
       * @handle patternMobile validator validator3
       * @description 校验函数返回 true 表示校验通过，false 表示不通过
       */
      patternMobile(val) {
        return state.pattern.test(val); //手机号码验证
      },
      validator(val) {
        return /^[\u4e00-\u9fa5]+$/.test(val); //只能输入中文
      },
      patternCard(val) {
        const [result, message] = cardid(val);
        if (result) {
          return message;
        }
      },
      //确定area列表
      confirmArea(arr) {
        state.popupShow = false;
        state.form.consigneeAddress = arr.map((item) => item.name).join(' ');
      },
      //关闭/打开area列表
      openOrCloseArea() {
        state.popupShow = !state.popupShow;
      },
      getInfo() {
        http
          .get(`/api/zyadposition/zyAdBannerInfo/${state.form.goodsId}`)
          .then(({ data: res }) => {
            console.log(res);
            if (res.code == 0) {
              sessionStorage.setItem('numCard', JSON.stringify(res));
              if (!res.zyNumberCardEntity.status) {
                router.replace({
                  name: 'receive-result',
                  query: { noexit: 1 },
                });
              }
              state.info = res.zyNumberCardDetailsEntity.info;
            } else {
              tool.alert({
                title: '系统异常',
                msg: res.msg,
              });
            }
          });
      },
      onSubmit(val) {
        const form = cloneDeep(state.form);
        if (!state.checked) {
          return tool.toast({
            msg: '请先阅读并同意协议以及公告',
            duration: 1500,
          });
        }
        http.post(`/zyorderslogistics/save`, form).then(({ data: res }) => {
          console.log(res);
          if (res.code == 0) {
            tool.alert(
              {
                title: '提交成功',
                msg: '卡片申请提交成功',
              },
              () => {
                location.reload();
              }
            );
          } else {
            tool.alert({
              title: '提交错误',
              msg: res.msg,
            });
          }
        });
      },
    };
    onMounted(() => {
      const { agentId, goodsId } = route.query;
      Object.assign(state.form, { agentId, goodsId });
      methods.getInfo();
    });
    return {
      ...toRefs(state),
      ...methods,
      areaList,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
.info {
  /deep/ img {
    width: 100%;
    height: auto;
  }
  word-break: break-all;
  width: 100%;
}
.activity-container {
  width: 100%;
  word-break: break-all;
  .submit {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    background: linear-gradient(
      137.7deg,
      rgba(255, 153, 0, 1) 0%,
      rgba(255, 106, 58, 1) 100%
    );
    color: #fff;
    margin: 24px auto;
    width: 568px;
  }
}
</style>